<template>
  <div class="appointment">
    <el-form ref="form" :model="queryInfo" label-width="80px" size="small">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="用户名">
            <el-input v-model="queryInfo.name" placeholder="请输入用户名" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="手机号">
            <el-input v-model="queryInfo.phone" placeholder="请输入手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="生产阶段">
            <el-select size="small" v-model="queryInfo.stage" placeholder="生产阶段">
              <el-option
                v-for="item in stages"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="预产期">
            <el-date-picker
              size="small"
              v-model="queryInfo.expected_at"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="预产期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="queryInfo.started_range"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="预产医院">
            <el-input v-model="queryInfo.expected_hospital" placeholder="预产医院" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getAppointmentList">查询</el-button>
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="exportAll">导出</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 280px)"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="来源">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.source === 1">系统</el-tag>
          <el-tag v-else-if="scope.row.source === 2" type="success">官网 h5</el-tag>
          <el-tag v-else type="info">官网 pc</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="用户" prop="name" />
      <el-table-column label="手机" prop="phone" />
      <el-table-column label="生产阶段" prop="stage" :formatter="stageFormatter" />
      <el-table-column label="预产期" prop="expected_at" />
      <el-table-column label="预产医院" prop="expected_hospital" show-overflow-tooltip />
      <el-table-column label="创建时间" prop="created_at" width="180" />
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            v-if="!scope.row.necessary"
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="30%" :visible.sync="appointmentFormVisible">
      <el-form
        :model="appointmentForm"
        :rules="appointmentRules"
        ref="appointmentForm"
        size="small"
        label-width="80px"
      >
        <el-form-item label="用户" prop="name">
          <el-input v-model="appointmentForm.name" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input
            v-model="appointmentForm.phone"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item label="生产阶段">
          <el-select v-model="appointmentForm.stage" placeholder="生产阶段">
            <el-option
              v-for="item in stages"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预产期">
          <el-date-picker
            v-model="appointmentForm.expected_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="预产期"
          />
        </el-form-item>
        <el-form-item label="预产医院">
          <el-input v-model="appointmentForm.expected_hospital" placeholder="预产医院" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAppointmentList,
  updateAppointment,
  deleteAppointment,
  exportAll
} from '@/services/appointment'

export default {
  name: 'Appointment',
  data () {
    return {
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      stages: [
        { label: '产前', value: 1 },
        { label: '分娩', value: 2 },
        { label: '产后', value: 3 }
      ],
      appointmentFormVisible: false,
      formTitle: '编辑',
      appointmentForm: {},
      appointmentRules: {}
    }
  },
  created () {
    this.getAppointmentList()
  },
  methods: {
    async getAppointmentList () {
      const { data } = await getAppointmentList(this.queryInfo)
      this.tableData = data.data
      this.total = data.meta.total
    },
    stageFormatter (row, column, cellValue, index) {
      const stageObj = {
        1: '产前',
        2: '分娩',
        3: '产后'
      }
      return stageObj[cellValue]
    },
    handleEdit (index, row) {
      if (this.$refs.appointmentForm) {
        this.$refs.appointmentForm.resetFields()
      }
      this.appointmentForm = { ...row }
      this.appointmentFormVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteAppointment(row.id)
        this.getAppointmentList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getAppointmentList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getAppointmentList()
    },
    reset () {
      this.queryInfo = {
        pageNum: 1,
        pageSize: 10
      }
      this.getAppointmentList()
    },
    cancel () {
      this.appointmentForm = {}
      this.appointmentFormVisible = false
    },
    save () {
      this.$refs.appointmentForm.validate(async valid => {
        if (valid) {
          try {
            await updateAppointment(this.appointmentForm.id, this.appointmentForm)
            this.appointmentForm = {}
            this.appointmentFormVisible = false
            this.getAppointmentList()
            this.$message({
              type: 'success',
              message: '预约编辑成功'
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: '预约编辑失败'
            })
          }
        }
      })
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `预约-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment {
  box-sizing: border-box;
  padding: 15px;
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}
</style>
