import request from '@/utils/request'
import qs from 'qs'

// 列表
export const getAppointmentList = params => {
  return request({
    method: 'GET',
    url: '/appointments',
    params
  })
}

// 修改
export const updateAppointment = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/appointments/${id}`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 删除
export const deleteAppointment = id => {
  return request({
    method: 'DELETE',
    url: `/appointments/${id}`
  })
}

// 导出
export const exportAll = () => {
  return request({
    method: 'POST',
    url: '/appointments/export',
    responseType: 'blob'
  })
}
